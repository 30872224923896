import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import TokenInfo from 'components/TokenInfo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Chameleon makes use of a list of predefined elevation values that simulate a
three-dimensional stack above the surface of an interface. The relative distance
between two surfaces is measured along the z-axis, where the degree of elevation
can be enhanced with shadows.`}</p>
    <h2 {...{
      "id": "z-index-order",
      "style": {
        "position": "relative"
      }
    }}>{`Z-index Order`}</h2>
    <p>{`UI elements are depicted on a relative distance between each other. To
concistenly place them on top of each other, every element makes use of a
z-index order. This emphasizes a layering effect and hierarchy in our
interfaces.`}</p>
    <TokenInfo platform="js" scope="zIndex" mdxType="TokenInfo" />
    <h2 {...{
      "id": "shadows",
      "style": {
        "position": "relative"
      }
    }}>{`Shadows`}</h2>
    <p>{`Shadows can increase the relative depth for a given z-index order. An element
with a smaller and sharper shadow indicates that the surface is closer to the
surface behind it. Larger and softer shadows indicate more distance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex hideEditor",
        "wrap": "flex",
        "hideEditor": true
      }}>{`<Paper elevation={-1} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column" textAlign="center"><Caption color="grey80" textAlign="center">Shadow -1</Caption></Paper>
<Paper elevation={0} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">Shadow 0</Caption></Paper>
<Paper elevation={1} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">Shadow 1</Caption></Paper>
<Paper elevation={2} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">Shadow 2</Caption></Paper>
<Paper elevation={3} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">Shadow 3</Caption></Paper>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      